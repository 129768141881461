.goleo-table {
  width: 375px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  margin: 30px 0px;
  padding: 15px 5px;
  background-color: black;
  border-radius: 10%;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.goleo-title {
  color: white;
  font-size: 60px;
}

.goleo-row {
  display: grid;
  width: 90%; /* Adjust the width of the container */
  grid-template-columns: 10% 45% 35% 10%;
  grid-template-rows: 35px; /* Create a single row with a fixed height */
}

.goleo-column {
  display: flex; /* Make the grid item a flex container */
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  /* background-image: linear-gradient(#121b69, #0f112a); */
  /* border: 1px solid #000; To visualize the grid item's boundaries */
  height: 100%; /* Make the grid item take up the full height of the row */
}

.goleo-column.JUEVES_5v5_MIXTO {
  background-image: linear-gradient(#e6c12d, #0f112a);
}

.goleo-column.MIERCOLES_5v5 {
  background-image: linear-gradient(#2de6d7, #0f112a);
}

.goleo-column.MARTES_5v5_FEMENIL {
  background-image: linear-gradient(#b5206d, #0f112a);
}

.goleo-column.position {
  background-image: none;
}

.goleo-column.name {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.goleo-column.goals {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.goleo-container {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgb(6, 6, 6);
  border-radius: 15px;
  width: 95%;
  height: 60%;
}

.goleo-container.position {
  justify-content: center;
  width: 70%;
}

.goleo-item {
  background-color: white;
  border-radius: 15px;
  font-size: 20px;
  font-weight: 800;
  text-align: center;
  display: flex; /* Make the header item itself a flex container */
  align-items: center; /* Center text vertically within the item */
  justify-content: center; /* Center text horizontally within the item */
}

.goleo-item.position {
  border-radius: 20%;
  width: 95%;
  height: 90%;
}

.goleo-item.name {
  justify-content: left;
  width: 98%;
  height: 90%;
  flex-wrap: wrap;
  padding-left: 6px;
  font-size: 18px;
  line-height: 100%;
}

.goleo-item.goals {
  width: 90%;
  height: 90%;
}

@media (min-width: 768px) {
  .goleo-table {
    width: 50vw;
  }
  .goleo-title {
    font-size: 6vw;
  }
  .goleo-row {
    grid-template-rows: 3vw; /* Create a single row with a fixed height */
  }
  .goleo-container {
    height: 90%;
  }
  .goleo-item.name {
    justify-content: left;
    width: 98%;
    height: 90%;
    font-size: 2vw;
  }
  .goleo-item.position {
    font-size: 2vw;
  }
  .goleo-item.goals {
    font-size: 2vw;
  }
}
