/* Layout */
.layout {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.left,
.right {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
}

@media (min-width: 768px) {
  .layout {
    flex-direction: row;
    justify-content: space-evenly;
  }

  .left {
    flex: 0.6;
    justify-content: flex-start;
    align-items: center;
    min-height: 1000px;
  }

  .right {
    flex: 0.4;
    justify-content: flex-start;
    align-items: center;
    min-height: 1000px;
  }
}

/* Section Container */
.section-container {
  display: flex;
  width: 95%;
  background-color: #111111; /* Slightly lighter than white background */
  padding: 50px 10px;
  justify-content: center;
  border-radius: 8px;
  box-shadow: 0 0 10px 1px rgb(179, 179, 179); /* Raised effect */
  margin-bottom: 20px;
}
