.menu-container {
  /* background-color: #1f1f1f; Dark background */
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.menu-item {
  background-color: #0000007d; /* Dark background */
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  font-size: 20px;
  padding: 12px 20px;
  text-decoration: none; /* Removes underline from links */
  border-bottom: 1px solid #ffffff; /* Subtle borders between items */
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.menu-item:hover {
  background-color: #dfdfdf5f; /* Hover effect */
  color: black;
}

.arrow {
  font-weight: bold;
  font-size: 24px;
}

body {
  background-color: rgb(21, 21, 140);
}
