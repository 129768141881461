.banner-container {
  width: 100%; /* Full width */
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 64px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  position: relative;
  overflow: hidden;
  background-position: center; /* Centers the background image */
  background-size: cover; /* Cover ensures the container is fully covered */
  background-repeat: no-repeat; /* Prevents the image from repeating */
  margin-bottom: 40px;
}

.banner-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.banner-container h1 {
  font-size: 20vw; /* Adjust font size based on the width of the viewport */
  color: white;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Optional for better visibility */
  z-index: 2;
  margin: 50px 0px;
}
