.table-container {
  width: 375px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px 5px;
  padding-bottom: 30px;
  border-radius: 10px;
  border-bottom-left-radius: 0%;
  border-bottom-right-radius: 0%;
}

.table-title {
  color: white;
  font-size: 60px;
  text-align: center;
  line-height: 55px;
  margin-bottom: 15px;
}

.row-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 350px;
  padding-bottom: 10px;
  padding-top: 5px;
  border-radius: 10px;
}

@media (min-width: 768px) {
  .table-container {
    width: 50vw;
  }
  .table-title {
    font-size: 6vw;
    margin-bottom: 30px;
  }
  .row-container {
    width: 100%;
  }
}
