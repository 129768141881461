.week-header {
  background-color: black;
  text-align: center;
  width: 90%;
  font-size: 30px;
  align-self: center;
}

.buttons-container {
  display: flex;
  background-color: black;
  justify-self: center;
  width: fit-content;
  align-self: center;
  font-size: 18px;
  margin-top: 7px;
  margin-bottom: 15px;
}

/* Partidos.css */
.fixtures-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
  padding: 20px 0;
  touch-action: pan-y; /* Allow vertical scrolling, prevent horizontal */
}

.matchcard-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 90%;
  align-self: center;
  align-items: center;
}

.period-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  gap: 1em;
}

.period-navigation span {
  font-weight: bold;
  font-size: 1.5em;
  color: white;
}

.period-navigation button {
  width: 50px;
}

.games-container {
  position: relative;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease-in-out;
}

@media (min-width: 768px) {
  .period-navigation span {
    font-weight: bold;
    font-size: 3vw;
    color: white;
  }

  .period-navigation button {
    width: 8vw;
  }
}
