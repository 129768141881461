.match-card {
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 250px;
  height: 100px;
  gap: 5px;
  padding-left: 5px;
  border-radius: 8px;
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  background-color: var(--tertiary-color);
  box-shadow: 0 0 10px 0.5px var(--shadow-color); /* Raised effect */
}

.details {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-style: solid;
  border-color: white;
  border-radius: 8%;
  border-width: 0.5px;
  width: 30%;
  height: 100%;
}

.time {
  font-size: 25px;
  color: white;
}

.date {
  font-size: 20px;
  color: white;
}

.league {
  margin-top: 5px;
  font-size: 15px;
  color: white;
}

.teamsScore-Container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  margin: 3px 0px;
  height: 95%;
  width: 75%;
  background-color: rgb(0, 0, 0);
  border-radius: 8px;
}

.team-name-text {
  font-size: 15px;
  font-weight: 600;
}

.teamScoreContainer {
  display: flex;
  align-items: center; /* Align items vertically centered */
  padding: 3px 5px 3px 10px; /* Optional: Add some padding */
  justify-content: space-between; /* Distribute space between items */
  border-radius: 8px;
  height: 48%;
  width: 98%;
  background-image: linear-gradient(
    90deg,
    var(--theme-color),
    var(--secondary-color)
  );
}

.scoreContainer.played {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  background-color: rgb(228, 228, 228);
  border-radius: 10%;
  color: black;
  font-size: 12px;
  text-align: center;
  vertical-align: middle;
  font-weight: bold;
  width: 25px;
  height: 25px;
  padding: 10px 0px;
}

.scoreContainer.unplayed {
  font-size: 26px;
  font-weight: bold;
  margin-right: 5px;
}

@media (min-width: 768px) {
  .time {
    font-size: 3vw;
  }

  .date {
    font-size: 2vw;
  }

  .league {
    font-size: 2vw;
  }
  .team-name-text {
    font-size: 2vw;
  }
  .scoreContainer.played {
    font-size: 2.5vw;
    width: 5vw;
    height: 90%;
    text-align: center;
    padding: 0px;
  }
  .details {
    height: 100%;
  }
  .match-card {
    width: 100%;
    height: 12vw;
    box-shadow: none;
  }
}
