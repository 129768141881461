.team-row {
  display: grid;
  grid-template-columns: 10% 35% 25% 20% 10%;
  grid-template-rows: 30px; /* Create a single row with a fixed height */
  width: 100%; /* Adjust the width of the container */
}

.team-column {
  display: flex; /* Make the grid item a flex container */
  justify-content: center; /* Horizontally center content */
  align-items: center; /* Vertically center content */
  /* background-color: lightblue;
  border: 1px solid #000; To visualize the grid item's boundaries */
  height: 100%; /* Make the grid item take up the full height of the row */
  background-image: linear-gradient(var(--theme-color), var(--secondary-color));
}

.team-item.odd {
  background-color: var(--tertiary-color);
}

.team-column.position {
  background-image: none;
}

.team-column.teamname {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}
.team-column.points {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.team-column.position {
  background-color: transparent;
}

.team-container {
  align-self: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  background-color: #000000;
  border-radius: 5px;
  width: 95%;
  height: 90%;
}

.team-container.position {
  justify-content: center;
  width: 70%;
}

.team-container.points {
  justify-content: center;
  width: 80%;
}
.team-container.games {
  justify-content: center;
  width: 100%;
}

/* Container for Item Data*/
.team-item {
  background-color: white;
  border-radius: 15%;
  font-size: 14px;
  font-weight: 800;
  text-align: center;
  display: flex; /* Make the header item itself a flex container */
  align-items: center; /* Center text vertically within the item */
  justify-content: center; /* Center text horizontally within the item */
}
/* Container for Item Data*/

.team-item.position {
  border-radius: 20%;
  width: 95%;
  height: 85%;
  font-size: 18px;
}

.team-item.teamname {
  justify-content: left;
  width: 98%;
  height: 90%;
  flex-wrap: wrap;
  padding-left: 6px;
  font-size: 15px;
  line-height: 100%;
  border-radius: 5px;
  font-weight: 400;
}

.team-item.data {
  font-family: "Montserrat", sans-serif;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  font-weight: 800;
  font-size: 65%;
}

.team-item.points {
  font-family: "Montserrat", sans-serif;
  width: 95%;
  height: 90%;
  font-size: 14px;
}

@media (min-width: 768px) {
  .team-row {
    grid-template-rows: 3vw; /* Create a single row with a fixed height */
  }
  .team-item.data {
    border-radius: 100%;
    width: 2.5vw;
    height: 2.5vw;
    font-size: 1.25vw;
  }
  .team-item.teamname {
    justify-content: left;
    width: 98%;
    height: 90%;
    font-size: 2vw;
  }
  .team-item.position {
    font-size: 2vw;
  }
  .team-item.points {
    font-size: 1.4vw;
  }
  .team-container.games {
    display: flex;
    justify-content: space-evenly;
  }
}
