/* Base Navbar Styling */
.navbar {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1050;
  background-color: #ffffff;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Add shadow for fixed navbar */
  height: 70px;
}

/* Navbar Visible */
.navbar-visible {
  transform: translateY(0); /* Fully visible */
}

/* Navbar Hidden */
.navbar-hidden {
  transform: translateY(-100%); /* Move navbar out of view */
}

/* Logo Styling */
.logo-container {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 50px; /* Adjust logo size */
}

/* Navbar Links */
.nav-item h2 {
  font-size: 30px;
  margin: 0 15px;
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
}

.nav-item:hover h2 {
  color: #007bff;
  text-decoration: underline;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .navbar-logo {
    height: 40px;
  }

  .nav-item h2 {
    font-size: 26px;
    margin: 0 10px;
  }
}
